import { merge } from 'lodash'
import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { parseISO, isFuture } from 'date-fns'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ChartDataset
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'

// @mui imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/Warning'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import i18n from 'i18n'
import theme from 'assets/theme'
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { KNTemperatureIcon, KNTemperatureWarningIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import KNDetailsPopoverGraphLoader from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverGraphLoader'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { temperatureDataTransformer, temperatureRangesTransformer } from './TripDetails.helpers'
import { getTripLegs, getTripVehicleTemperatures } from './TripDetails.service'
import { LegData, PharmaExtendedInformation } from './TripDetails.types'

// Functional
import { getTemperatureChartOptions, getDarkModeColors } from 'global/helpers/charts'

interface TemperatureProps {
  trip: TripData
  preloadedLegs?: LegData[]
  withGraph?: boolean
  weblinkToken?: string
}

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip)

const getBoxBoundaries = (temperatureRanges: PharmaExtendedInformation[]) => {
  if (temperatureRanges.length === 0) {
    return null
  }

  return {
    plugins: {
      annotation: {
        annotations: temperatureRanges.map((value) => {
          return {
            drawTime: 'beforeDraw',
            type: 'box',
            yMin: value.minimum,
            yMax: value.maximum,
            backgroundColor: theme.palette.primary.dark,
            borderColor:theme.palette.primary.dark,
          }
        }),
      }
    }
  }
}

const Temperature = ({ trip, preloadedLegs, withGraph = false, weblinkToken }: TemperatureProps): ReactElement | null => {
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [loading, setLoading] = useState(true)
  const [temperatureDatasets, setTemperatureDatasets] = useState<ChartDataset<'line'>[]>([])
  const [temperatureRanges, setTemperatureRanges] = useState<PharmaExtendedInformation[]>([])

  const hasTemperatureError = trip.temperatureControlled && trip.tracked && !trip.lastTemperatureTimestamp

  const {
    anchorEl: temperatureDetailsAnchorEl,
    open: temperatureDetailsOpen,
    handleClick: handleTemperatureDetailsClick,
    handleClose: handleTemperatureDetailsClose,
  } = usePopoverState()

  const handleTemperatureDetailsClickWrapper = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      handleTemperatureDetailsClick(event)
      analyticsEvent('polestar_cs_trip_temperature_details_popover', [getRouteName(location.pathname)])
    },
    [location]
  )

  const fetchData = useCallback(async (): Promise<void> => {
    setLoading(true)
    try {
      const [legs, vehicleTemperaturesData] = await Promise.all([
        preloadedLegs ?? getTripLegs(trip.entityId, weblinkToken),
        withGraph ? getTripVehicleTemperatures(trip.entityId, weblinkToken) : [],
      ])
      setTemperatureDatasets(temperatureDataTransformer(vehicleTemperaturesData))
      setTemperatureRanges(temperatureRangesTransformer(legs))
    } catch (error) {
      setTemperatureDatasets([])
      setTemperatureRanges([])
    }
    setLoading(false)
  }, [trip, preloadedLegs, weblinkToken])

  useEffect(() => {
    if (temperatureDetailsOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData()
    }
  }, [temperatureDetailsOpen])

  if (!trip.temperatureControlled && (!withGraph || (withGraph && !trip.lastTemperatureTimestamp))) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleTemperatureDetailsClickWrapper}
        size="small"
        sx={{
          margin: '-5px 0 !important',
          color: hasTemperatureError ? 'warning.main' : 'primary.main',
        }}
      >
        {hasTemperatureError ? (
          <KNTemperatureWarningIcon data-test="temperature-icon" />
        ) : (
          <KNTemperatureIcon data-test="temperature-icon" />
        )}
      </IconButton>

      <KNDetailsPopover
        open={temperatureDetailsOpen}
        onClose={handleTemperatureDetailsClose}
        anchorEl={temperatureDetailsAnchorEl}
        rightAligned
      >
        {withGraph && trip.tracked && (
          <>
            {hasTemperatureError && (
              <KNDetailsPopoverSection
                items={[
                  (
                    <KNCaption
                      key="warning"
                      color="inherit"
                      icon={<WarningIcon />}
                      sx={{
                        paddingX: 2,
                        paddingY: 0.25,
                      }}
                    >
                      {t('screens.cs.trip_dashboard.card.temperature_warning')}
                    </KNCaption>
                  ),
                ]}
              />
            )}
            {loading ? (
              <KNDetailsPopoverGraphLoader />
            ) : (
              <KNDetailsPopoverSection
                items={[
                  <Box
                    key="chart"
                    sx={{
                      width: isMobile ? '20rem' : '30rem',
                      height: isMobile ? '10rem' : '20rem',
                      marginTop: '-1px',
                      paddingX: 1,
                      paddingY: 0.5,
                    }}
                  >
                    <Line
                      options={merge(getTemperatureChartOptions(), getDarkModeColors(), getBoxBoundaries(temperatureRanges))}
                      data={{ datasets: temperatureDatasets }}
                    />
                  </Box>,
                ]}
              />
            )}
          </>
        )}
        {temperatureRanges.map((temperatureRange) => (
          <KNDetailsPopoverList
            key={temperatureRange.setPoint}
            items={[
              {
                label: t('screens.cs.trip_details.temperature_details.set_point'),
                value: t('screens.cs.trip_details.temperature_details.temperature_in_degrees', { temperature: temperatureRange.setPoint }),
              },
              {
                label: i18n.t('screens.cs.trip_details.temperature_details.required_temperature_range'),
                value: i18n.t('screens.cs.trip_details.temperature_details.temperature_between_ranges', { minTemperature: temperatureRange.minimum, maxTemperature: temperatureRange.maximum }),
              },
            ]}
          />
        ))}
      </KNDetailsPopover>
    </>
  )
}

export default Temperature
