import { createContext, useState, ReactElement, useEffect } from 'react'

// Functional //
import i18n from '../../i18n'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { AuthImpl } from './Auth.service'
import { getCompanies } from './Company.service'
import { getUserPreferences, storeLanguage } from '../../screens/UserManager/UserManager.service'
import { clearSelectedCompany, getSelectedCompany, setSelectedCompany } from './User.helpers'
import { analyticsEvent } from 'global/helpers/analytics'

// Types //
import { Auth } from './Auth.types'
import { KNUser, UserContextProps } from './UserContext.types'
import { UserPreferencesData } from 'screens/UserManager/UserManager.types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const UserContext = createContext<UserContextProps>({} as UserContextProps)

export const UserProvider = ({ children }): ReactElement => {
  const auth: Auth = new AuthImpl()
  const [user, setUser] = useState<KNUser>()
  const [userError, setUserError] = useState<string>()
  const [userLoading, setUserLoading] = useState(true)
  const [userResetLoading, setUserResetLoading] = useState(false)
  const [userResetResult, setUserResetResult] = useState('')
  const [userPreferences, setUserPreferences] = useState<UserPreferencesData>({})

  const logoutUser = (): void => {
    auth.signOut()
    clearSelectedCompany()
    analyticsEvent('polestar_user_logged_out')
    window.location.replace('/login')
  }

  useEffect(() => {
    if (user) {
      const fetchPreferences = async (): Promise<void> => {
        let preferences = await getUserPreferences()
        if (!preferences.dashboardPreferences) {
          preferences = { ...preferences, dashboardPreferences: { pageSize: 10 } }
        } else {
          preferences = { ...preferences }
        }
        setUserPreferences(preferences)
      }
      void fetchPreferences()
    }
  }, [user])

  const loginUser = async (email: string, password: string): Promise<void> => {
    setUserLoading(true)
    setUserError(undefined)
    clearSelectedCompany()
    try {
      await auth.signInWithEmailAndPassword(email, password)
      await loadUser()
      analyticsEvent('polestar_user_logged_in')
      analyticsEvent('shipmentinsight_login')
    } catch (error) {
      if (getErrorMessage(error) === '401') {
        setUserError(i18n.t('errors.LoginFailed'))
      } else {
        setUserError(i18n.t('errors.GenericError'))
      }
    }
    setUserLoading(false)
  }

  const resetUser = async (email: string): Promise<void> => {
    setUserResetLoading(true)
    try {
      await auth.sendPasswordResetEmail(email)
      setUserResetResult('success')
      analyticsEvent('shipmentinsight_reset-password')
    } catch {
      setUserResetResult('error')
    }
    setUserResetLoading(false)
  }

  const resetUserResult = (): void => {
    setUserResetResult('')
  }

  const verifyPasswordResetCode = async (oobCode: string): Promise<void> => {
    setUserResetLoading(true)
    try {
      await auth.verifyPasswordResetCode(oobCode)
    } finally {
      setUserResetLoading(false)
    }
  }

  const confirmPasswordReset = async (oobCode: string, newPassword: string): Promise<void> => {
    setUserResetLoading(true)
    try {
      await auth.confirmPasswordReset(oobCode, newPassword)
    } finally {
      setUserResetLoading(false)
    }
  }

  const loadUser = async (): Promise<void> => {
    setUserLoading(true)
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const loginRedirect = queryParams.get('redirect')
      const loginCompanyCid = queryParams.get('company')
      const user = await auth.getCurrentUser()
      if (user) {
        const companies = await getCompanies(user.idToken)
        setUser({
          ...user,
          companies: companies,
          type:
            companies.length > 0 &&
            companies.some((value) => value.cid === 'zb2rhXuNLamWn2NzucL623EfT9871AWq1Bv5FL7ij3AyguuaF')
              ? 'Admin'
              : 'User',
        })
        if (companies.length > 0 && !getSelectedCompany()) {
          if (loginCompanyCid) {
            setSelectedCompany(companies.find((company) => company.cid === loginCompanyCid) ?? companies[0])
          } else {
            setSelectedCompany(companies[0])
          }
        }
        const localLang = localStorage.getItem('userLanguage')
        if (localLang) await i18n.changeLanguage(localLang)
        else if (user.language) {
          await i18n.changeLanguage(user.language.toLowerCase())
        } else {
          await storeLanguage({
            language: i18n.language.slice(0, 2).toUpperCase(),
          })
        }
      }
      if (loginRedirect) {
        window.location.replace(loginRedirect)
      }
    } catch {
      auth.signOut()
    }
    setUserLoading(false)
  }

  const reloadUserCompanies = async (): Promise<void> => {
    setUserLoading(true)
    if (user) {
      await getCompanies(user.idToken).then((response) => {
        setUser({
          ...user,
          companies: response,
        })
        if (response.length > 0 && !getSelectedCompany()) {
          setSelectedCompany(response[0])
        } else {
          window.location.replace('/not-found')
        }
      })
      setUserLoading(false)
    } else {
      setUserLoading(false)
    }
  }

  return (
    <div>
      <UserContext.Provider
        value={{
          user,
          userLoading,
          userError,
          userPreferences,
          logoutUser,
          loadUser,
          reloadUserCompanies,
          loginUser,
          resetUser,
          verifyPasswordResetCode,
          confirmPasswordReset,
          userResetLoading,
          userResetResult,
          resetUserResult,
        }}
      >
        {children}
      </UserContext.Provider>
    </div>
  )
}
