import { useState, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import SmsIcon from '@mui/icons-material/Sms'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import MessageIcon from '@mui/icons-material/Message'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNMenu from 'components/KN_Molecules/KNMenu/KNMenu'
import KNMenuItem from 'components/KN_Molecules/KNMenu/KNMenuItem'
import useMenuState from 'components/KN_Molecules/KNMenu/useMenuState'
import { KNShareIcon, KNEditIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import ShareDialog from 'screens/TripDashboard/ShareDialog'
import AssignDriverDialog from 'screens/TripDashboard/AssignDriverDialog'
import AssignVehicleDialog from 'screens/TripDashboard/AssignVehicleDialog'
import SmsDriverDialog from 'screens/TripDashboard/SmsDriverDialog'
import TripHeader from 'screens/TripDashboard/TripHeader'
import TripCargo from 'screens/TripDashboard/TripCargo'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

// Types
import { KNMenuAction } from 'components/KN_Molecules/KNMenu/types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { LegData } from './TripDetails.types'

export interface TripSummaryProps {
  trip: TripData
  legs: LegData[]
  weblinkToken?: string
  onChange?: (updatedTrip?: TripData) => void
}

const TripSummary = ({ trip, legs, weblinkToken, onChange }: TripSummaryProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [assignDriverDialogOpen, setAssignDriverDialogOpen] = useState(false)
  const [assignVehicleDialogOpen, setAssignVehicleDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [smsDialogOpen, setSmsDialogOpen] = useState(false)

  const handleSmsClick = useCallback((): void => setSmsDialogOpen(true), [])
  const handleSmsAction = useCallback((): void => {
    setSmsDialogOpen(false)
  }, [])
  const handleSmsClose = useCallback((): void => {
    setSmsDialogOpen(false)
  }, [])

  const handleShareClick = useCallback((): void => {
    setShareDialogOpen(true)
    analyticsEvent('polestar_cs_share_trip_button_clicked')
  }, [])
  const handleShareAction = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])
  const handleShareClose = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])

  const handleAssignDriverClick = useCallback((): void => {
    setAssignDriverDialogOpen(true)
    analyticsEvent('polestar_cs_assign_driver_button_clicked')
  }, [])
  const handleAssignDriverAction = useCallback((updatedTrip: TripData): void => {
    setAssignDriverDialogOpen(false)
    onChange?.(updatedTrip)
  }, [])
  const handleAssignDriverClose = useCallback((): void => {
    setAssignDriverDialogOpen(false)
  }, [])

  const handleAssignVehicleClick = useCallback((): void => {
    setAssignVehicleDialogOpen(true)
    analyticsEvent('polestar_cs_assign_vehicle_button_clicked')
  }, [])
  const handleAssignVehicleAction = useCallback((updatedTrip: TripData): void => {
    setAssignVehicleDialogOpen(false)
    onChange?.(updatedTrip)
  }, [])
  const handleAssignVehicleClose = useCallback((): void => {
    setAssignVehicleDialogOpen(false)
  }, [])

  return (
    <>
      <Paper
        data-test="trip-summary-container"
        elevation={16}
        sx={{
          padding: 2,
        }}
        className="hoverable"
      >
        <TripHeader trip={trip} legs={legs} stacked size="large" weblinkToken={weblinkToken} />
        <Grid container my={1}>
          <Grid item xs={12} md={6} lg={12}>
            <TripCargo trip={trip} data-test="cargo-summary-info" />
          </Grid>
          {isMobile && trip.carrierInstructions && (
            <Grid item xs={12}>
              <Stack>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('screens.cs.trip_dashboard.trip_details.instructions')}
                </KNTypography>
                {trip.carrierInstructions}
              </Stack>
            </Grid>
          )}
          {!weblinkToken && (
            <>
              <Grid item xs={6} md={3} lg={6}>
                <Stack data-test="driver-name" alignItems="start">
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('screens.cs.trip_dashboard.card.columns.driver')}
                  </KNTypography>
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    sx={{ width: '100%', paddingRight: '1rem !important' }}
                  >
                    <KNTypography component="p" variant="textLG" color="text.main" noWrap>
                      {trip.assignedDriver
                        ? trip.assignedDriver.name
                        : t('screens.cs.trip_dashboard.card.not_assigned')}
                    </KNTypography>
                    <Tooltip
                      title={t('screens.cs.trip_dashboard.card.actions.assign_driver')}
                      enterDelay={500}
                      placement="top"
                      arrow
                    >
                      <IconButton
                        data-test="assign-driver-button"
                        onClick={handleAssignDriverClick}
                        size="small"
                        className="hover-display-flex"
                        sx={{ color: 'primary.main', marginY: '-5px !important' }}
                      >
                        <KNEditIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={6}>
                <Stack data-test="vehicle-summary" alignItems="start">
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('screens.cs.trip_dashboard.card.columns.vehicle')}
                  </KNTypography>
                  <Stack spacing={0.5} direction="row" alignItems="center" sx={{ width: '100%' }}>
                    <KNTypography component="p" variant="textLG" color="text.main" noWrap>
                      {trip.assignedVehicle && trip.assignedVehicle.displayLicensePlate}
                      {trip.secondaryAssignedVehicle && (
                        <>
                          <br />
                          {trip.secondaryAssignedVehicle.displayLicensePlate}
                        </>
                      )}
                      {!trip.assignedVehicle &&
                        !trip.secondaryAssignedVehicle &&
                        t('screens.cs.trip_dashboard.card.not_assigned')}
                    </KNTypography>
                    <Tooltip
                      title={t('screens.cs.trip_dashboard.card.actions.assign_vehicle')}
                      enterDelay={500}
                      placement="top"
                      arrow
                    >
                      <IconButton
                        data-test="assign-vehicle-button"
                        onClick={handleAssignVehicleClick}
                        size="small"
                        className="hover-display-flex"
                        sx={{ color: 'primary.main', marginY: '-5px !important' }}
                      >
                        <KNEditIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
        <Stack direction="row" justifyContent="end" alignItems="center">
          {(!weblinkToken || (weblinkToken && !isMobile)) && (
            <KNButton
              data-test="share-trip-button"
              data-guide="trip-share"
              variant="text"
              endIcon={<KNShareIcon />}
              onClick={handleShareClick}
            >
              {t('screens.cs.trip_dashboard.card.actions.share_trip_short')}
            </KNButton>
          )}
          {trip.assignedDriver?.contactNumber && (
            <KNButton data-test="sms-driver-button" variant="text" endIcon={<MessageIcon />} onClick={handleSmsClick}>
              {t('screens.cs.trip_dashboard.card.actions.sms_driver')}
            </KNButton>
          )}
        </Stack>
      </Paper>

      <AssignDriverDialog
        payload={{
          trip,
        }}
        open={assignDriverDialogOpen}
        onAction={handleAssignDriverAction}
        onClose={handleAssignDriverClose}
      />

      <AssignVehicleDialog
        payload={{
          trip,
        }}
        open={assignVehicleDialogOpen}
        onAction={handleAssignVehicleAction}
        onClose={handleAssignVehicleClose}
      />

      <SmsDriverDialog
        payload={{
          trip,
        }}
        open={smsDialogOpen}
        onAction={handleSmsAction}
        onClose={handleSmsClose}
      />

      <ShareDialog
        payload={{
          trip,
          weblinkToken,
        }}
        open={shareDialogOpen}
        onAction={handleShareAction}
        onClose={handleShareClose}
      />
    </>
  )
}

export default TripSummary
