import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { addDays, subDays, startOfDay, endOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { analyticsEvent } from 'global/helpers/analytics'
import { TripListContext } from 'context/trips/TripListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'
import KNDatePickerNext from 'components/KN_Molecules/KNDatePicker/KNDatePickerNext'
import { BranchesData } from './TripDashboard.types'

interface TripListFiltersProps {
  branches?: BranchesData
}

const TripListFilters = ({ branches }: TripListFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      tripListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    tripListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_cs_reset_trip_filters')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid data-test="trips-filter" data-guide="trip-filters" container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={tripListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
              if (newValue.length > 0) {
                analyticsEvent('polestar_cs_trip_filters', ['keywords', newValue.join(', ')])
              }
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} size="small" placeholder={t('screens.cs.trip_dashboard.filters.search')} data-test="search-input" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} data-test="status-dropdown">
          <KNAdvancedDropdown
            placeholder={t('screens.cs.trip_dashboard.filters.status')}
            options={[
              { value: 'NEW', label: t('screens.cs.trip_dashboard.statuses.new') },
              { value: 'TRIP_ASSIGNED', label: t('screens.cs.trip_dashboard.statuses.assigned') },
              { value: 'IN_PROGRESS', label: t('screens.cs.trip_dashboard.statuses.in_progress') },
              { value: 'DELIVERED', label: t('screens.cs.trip_dashboard.statuses.delivered') },
              { value: 'COMPLETED', label: t('screens.cs.trip_dashboard.statuses.completed') },
            ]}
            value={tripListState.filters.status ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                status: newValue,
              })
            }}
            size="small"
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KNDatePickerNext
            value={{ from: tripListState.filters.fromDate ?? undefined, to: tripListState.filters.toDate ?? undefined }}
            minDate={startOfDay(subDays(new Date(), 30))}
            maxDate={endOfDay(addDays(new Date(), 60))}
            onChange={(value): void => {
              setPayload({
                fromDate: value?.from,
                toDate: value?.to,
              })
            }}
            placeholderFrom={t('screens.cs.trip_dashboard.filters.date_from')}
            placeholderTo={t('screens.cs.trip_dashboard.filters.date_to')}
          />
        </Grid>
        <Grid item xs={12} sm={2} data-test="branch-dropdown">
          <KNAdvancedDropdown
            placeholder={t('screens.cs.trip_dashboard.filters.branches')}
            options={
              branches
                ? Object.entries(branches).map((branch) => {
                    return { value: branch[0], label: branch[1] }
                  })
                : []
            }
            value={tripListState.filters.branches ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                branches: newValue,
              })
            }}
            size="small"
            multiple
          />
        </Grid>
      </Grid>
      {tripListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          data-test="reset-trip-filters-button"
          onClick={handleResetClick}
          sx={{
            padding: 0,
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default TripListFilters
