import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import InfoIcon from '@mui/icons-material/Info'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close';

// KN imports
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import ShipmentsView from './ShipmentsView'
import StopsView from './StopsView'
import SequenceMapView from './SequenceMapView'
import LocationsMapView from './LocationsMapView'
import TripSummary from './TripSummary'
import TripLogs from './TripLogs'
import ViewSwitcher from './ViewSwitcher'

// Functional
import { getDrivers } from 'screens/DriverManager/DriverManager.service'
import { getVehicles } from 'screens/VehicleManager/VehicleManager.service'
import { isStopsViewAvailable } from 'screens/TripDetails/TripDetails.helpers'
import { getTrip } from 'screens/TripDashboard/TripDashboard.service'
import { getTripLegs, getTripLogs } from 'screens/TripDetails/TripDetails.service'
import { getUserPreferences } from 'screens/UserManager/UserManager.service'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { sleep } from 'global/helpers/sleep'

// Types
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { LegData, StopData, TripLogData } from './TripDetails.types'
import IconButton from '@mui/material/IconButton'

const TripDetails = (): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const { tripCid } = useParams<{
    tripCid: string
  }>()
  const [tripData, setTripData] = useState<TripData>()
  const [legsData, setLegsData] = useState<LegData[]>([])
  const [tripLogsData, setTripLogsData] = useState<TripLogData[]>([])
  const [loading, setLoading] = useState(true)
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [stopsViewAvailable, setStopsViewAvailable] = useState(false)

  const fetchTripDetailsData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(2000)
    } else {
      setLoading(true)
    }
    const [vehicles, drivers, trip, legs, tripLogs] = await Promise.all([
      tripListState.vehiclesPreloaded ? tripListState.vehicles : getVehicles(),
      tripListState.driversPreloaded ? tripListState.drivers : getDrivers(),
      getTrip(tripCid),
      getTripLegs(tripCid),
      getTripLogs(tripCid),
    ])
    if (!tripListState.vehiclesPreloaded) {
      tripListDispatch({ type: 'setVehicles', payload: vehicles })
    }
    if (!tripListState.driversPreloaded) {
      tripListDispatch({ type: 'setDrivers', payload: drivers })
    }
    trip.assignedDriver = drivers.find((driver) => driver.cid === trip.assignedDriverId)
    trip.assignedVehicle = vehicles.find((vehicle) => vehicle.licensePlate === trip.assignedVehicleLicensePlate)
    trip.secondaryAssignedVehicle = vehicles.find(
      (vehicle) => vehicle.licensePlate === trip.secondaryAssignedVehicleLicensePlate
    )
    setTripData(trip)
    setLegsData(legs)
    setTripLogsData(tripLogs)
    if (backgroundLoading) {
      tripListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setLoading(false)
    }
  }

  const handleStopsOnChange = useCallback(
    async (updatedStops: StopData[]): Promise<void> => {
      const updatedLegsData = legsData.map((leg) => {
        // NOTE: return one of the updated stops if wayPointCid matches, or original stop if not
        leg.wayPoints = leg.wayPoints.map(
          (stop) => updatedStops.filter((updatedStop) => updatedStop.wayPointCid === stop.wayPointCid)?.[0] ?? stop
        )
        return leg
      })
      setLegsData(updatedLegsData)
      await fetchTripDetailsData(true)
    },
    [legsData]
  )

  const handleTripOnChange = useCallback(
    async (updatedTrip?: TripData): Promise<void> => {
      if (updatedTrip) {
        const updatedTripData: TripData = {
          ...updatedTrip,
          assignedDriver: tripListState.drivers.find((driver) => driver.cid === updatedTrip.assignedDriverId),
          assignedVehicle: tripListState.vehicles.find(
            (vehicle) => vehicle.licensePlate === updatedTrip.assignedVehicleLicensePlate
          ),
          secondaryAssignedVehicle: tripListState.vehicles.find(
            (vehicle) => vehicle.licensePlate === updatedTrip.secondaryAssignedVehicleLicensePlate
          ),
        }
        setTripData(updatedTripData)
      }
      await fetchTripDetailsData(true)
    },
    [tripData]
  )

  useEffect(() => {
    if (!tripData) {
      return
    }
    setStopsViewAvailable(isStopsViewAvailable(tripData))
  }, [tripData])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchTripDetailsData()
    analyticsPageView('polestar/cs/trip_details')
  }, [])

  useEffect(() => {
    if (!tripData) {
      return
    }
    if (isMobile && stopsViewAvailable) {
      tripListDispatch({ type: 'setDetailsView', payload: 'stops' })
    }
    if (!stopsViewAvailable) {
      tripListDispatch({ type: 'setDetailsView', payload: 'shipments' })
    }
  }, [isMobile, tripData, stopsViewAvailable])

  return (
    <Container
      data-test="trip-details-container"
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.trip_details.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <Stack
          direction={{ xs: 'column-reverse', lg: 'row' }}
          spacing={{ xs: 2, lg: 4 }}
          alignItems="stretch"
          sx={{
            position: 'relative',
            marginX: {
              xs: '-0.5rem !important',
              md: '0 !important',
            },
            flexGrow: 1,
          }}
        >
          {tripListState.detailsView === 'shipments' && (
            <ShipmentsView trip={tripData!} legs={legsData} onChange={handleStopsOnChange} />
          )}
          {tripListState.detailsView === 'stops' && (
            <StopsView trip={tripData!} legs={legsData} onChange={handleStopsOnChange} />
          )}
          {tripListState.detailsView === 'sequence_map' && (
            <SequenceMapView trip={tripData!} legs={legsData} tripLogs={tripLogsData} onChange={handleStopsOnChange} />
          )}
          {tripListState.detailsView === 'locations_map' && (
            <LocationsMapView trip={tripData!} legs={legsData} tripLogs={tripLogsData} onChange={handleStopsOnChange} />
          )}
          <Stack
            spacing={2}
            sx={{
              position: { lg: 'sticky' },
              width: { lg: '360px' },
              maxHeight: { lg: 'calc(100vh - 2rem)' },
              flexShrink: 0,
              top: { xs: '56px', md: '16px' },
            }}
          >
            {!isMobile && <ViewSwitcher stopsViewAvailable={stopsViewAvailable} />}
            <TripSummary trip={tripData!} legs={legsData} onChange={handleTripOnChange} />
            <TripLogs legs={legsData} tripLogs={tripLogsData} stopsViewAvailable />
            <KNCaption icon={<InfoIcon />}>{t('screens.cs.trip_details.local_time')}</KNCaption>
          </Stack>
          <Link to={'/trips'} style={{ textDecoration: 'none'}}>
            <IconButton
              aria-label="close"
              sx={{
                m: -0.75,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Link>
        </Stack>
      )}
    </Container>
  )
}

export default TripDetails
