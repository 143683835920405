import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const KNWeightIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M8.54 9C8.19 8.41 8 7.73 8 7c0-2.194 1.806-4 4-4s4 1.806 4 4c0 .73-.19 1.41-.54 2H18c.95 0 1.75.67 1.95 1.56C21.96 18.57 22 18.78 22 19c0 1.097-.903 2-2 2H4c-1.097 0-2-.903-2-2 0-.22.04-.43 2.05-8.44C4.25 9.67 5.05 9 6 9h2.54Zm9.459 2 .011.047c1.325 5.278 1.834 7.402 1.964 7.953H4.026c.13-.551.639-2.675 1.964-7.953L6.001 11h11.998ZM12 5c-1.097 0-2 .903-2 2s.903 2 2 2 2-.903 2-2-.903-2-2-2Z"
    />
  </SvgIcon>
)

export const KNVolumeIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      strokeLinejoin="round"
      d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18-.21 0-.41-.06-.57-.18l-7.9-4.44c-.32-.17-.53-.5-.53-.88v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18.21 0 .41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9M12 4.15 6.04 7.5 12 10.85l5.96-3.35L12 4.15M5 15.91l6 3.38v-6.71L5 9.21v6.7m14 0v-6.7l-6 3.37v6.71l6-3.38Z"
    />
  </SvgIcon>
)

export const KNPalletIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M12 5c5.519 0 10 3.137 10 7s-4.481 7-10 7-10-3.137-10-7 4.481-7 10-7Zm0 2c2.737 0 5.184.877 6.688 2.314C19.484 10.073 20 10.987 20 12s-.516 1.927-1.312 2.686C17.184 16.123 14.737 17 12 17c-2.737 0-5.184-.877-6.688-2.314C4.516 13.927 4 13.013 4 12s.516-1.927 1.312-2.686C6.816 7.877 9.263 7 12 7Z"
    />
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M6.148 9.052v5.8H9.34v-.928H7.42v-1.576h1.752v-.928H7.42V9.98h1.92v-.928H6.148Zm5.368 5.896c.4 0 .724-.037.972-.112.248-.075.44-.205.576-.392.136-.187.227-.437.272-.752.045-.315.068-.712.068-1.192V9.052h-1.272v3.512c0 .256-.003.477-.008.664a1.847 1.847 0 0 1-.064.468.516.516 0 0 1-.18.28c-.083.061-.204.092-.364.092s-.281-.031-.364-.092a.516.516 0 0 1-.18-.28 1.847 1.847 0 0 1-.064-.468 25.057 25.057 0 0 1-.008-.664V9.052H9.628v3.512c0 .464.023.848.068 1.152.045.304.136.548.272.732.136.184.328.313.576.388.248.075.572.112.972.112Zm4.24-2.6.784 2.504h1.312l-.952-2.784c.277-.112.492-.277.644-.496.152-.219.228-.528.228-.928 0-.315-.043-.575-.128-.78a1.075 1.075 0 0 0-.392-.488 1.711 1.711 0 0 0-.676-.252 5.777 5.777 0 0 0-.98-.072h-1.648v5.8h1.272v-2.496h.44a.622.622 0 0 0 .096-.008Zm-.168-.768h-.368V9.9h.368c.176 0 .32.012.432.036.112.024.2.068.264.132a.488.488 0 0 1 .128.26c.021.109.032.249.032.42 0 .171-.011.309-.032.416a.46.46 0 0 1-.128.252.537.537 0 0 1-.264.128 2.124 2.124 0 0 1-.432.036Z"
    />
  </SvgIcon>
)

export const KNDangerousGoodsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="m21.36 10.46-7.82-7.82c-.85-.85-2.23-.85-3.08 0l-7.82 7.82c-.85.85-.85 2.23 0 3.08l7.82 7.82c.85.85 2.23.85 3.08 0l7.82-7.82c.85-.85.85-2.23 0-3.08ZM15.072 17l-2.946 2.946a.18.18 0 0 1-.252 0L8.928 17h6.144ZM4.928 13l2 2h10.144l2-2H4.928Zm2-4-2 2h14.144l-2-2H6.928Zm5.198-4.946L15.072 7H8.928l2.946-2.946a.18.18 0 0 1 .252 0Z"
    />
  </SvgIcon>
)

export const KNADRIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fill="#df7b2d"
      strokeLinejoin="round"
      d="m2.64 13.54 7.82 7.82c.85.85 2.23.85 3.08 0l7.82-7.82c.85-.85.85-2.23 0-3.08l-7.82-7.82c-.85-.85-2.23-.85-3.08 0l-7.82 7.82c-.85.85-.85 2.23 0 3.08Z"
    />
    <path
      fill="#000000"
      strokeLinejoin="round"
      d="M9.799 8.501h1.66c.441-.006.881.034 1.313.12.347.068.669.228.932.464.247.228.431.545.55.951.12.405.179.932.179 1.583v.811c0 .637-.059 1.154-.179 1.554-.119.399-.303.711-.55.936a1.978 1.978 0 0 1-.932.459 6.286 6.286 0 0 1-1.313.12h-1.66V8.501Zm9.602 6.998h-1.3l-.946-3.021a.893.893 0 0 1-.116.01h-.531v3.011h-1.535V8.501h1.989a6.98 6.98 0 0 1 1.182.086c.291.043.569.146.816.305.213.145.377.35.473.588.103.248.155.562.155.942 0 .482-.092.855-.275 1.119a1.681 1.681 0 0 1-.778.599l1.077 3.148-.211.211ZM4.37 15.27l1.616-6.769h1.853l1.68 6.998H7.955l-.289-1.467H6.082l-.28 1.467H4.599l-.229-.229Zm7.157-.804c.229.007.459-.019.681-.077a.638.638 0 0 0 .386-.313c.087-.159.143-.385.169-.681.025-.296.038-.689.038-1.178v-.454c0-.482-.013-.87-.038-1.163-.026-.293-.082-.52-.169-.68a.639.639 0 0 0-.386-.319 2.403 2.403 0 0 0-.681-.077h-.193v4.942h.193Zm-5.252-1.409h1.197l-.598-3.07-.599 3.07Zm10.677-1.506c.175.003.35-.011.522-.043a.651.651 0 0 0 .318-.155.563.563 0 0 0 .155-.304 2.72 2.72 0 0 0 .038-.502c0-.206-.013-.375-.038-.507a.589.589 0 0 0-.155-.313.622.622 0 0 0-.318-.16 2.573 2.573 0 0 0-.522-.043h-.444v2.027h.444Z"
    />
  </SvgIcon>
)

export const KNCustomsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path strokeLinejoin="round" d="M14 19H8v4l-7-7 7-7v4h6v6" />
    <path strokeLinejoin="round" d="M16 1v4h-6v6h6v4l7-7-7-7Z" />
  </SvgIcon>
)

export const KNPickupMarkerIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      strokeLinejoin="round"
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm-5 8 5-5 5 5h-3v4.043h-4V10H7Z"
    />
  </SvgIcon>
)

export const KNDeliveryMarkerIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      strokeLinejoin="round"
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm5 7.043-5 5-5-5h3V5h4v4.043h3Z"
    />
  </SvgIcon>
)

export const KNShareIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path strokeLinejoin="round" d="M14 8V3l9 9-9 9v-5.1c-5.493 0-10.064 1.748-13 5.1C2 16 7 9 14 8Z" />
  </SvgIcon>
)

export const KNSharedViaEmailIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M13 19c0-.34.04-.67.09-1H4V8l8 5 8-5v5.09c.72.12 1.39.37 2 .72V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9.09c-.05-.33-.09-.66-.09-1m7-13-8 5-8-5h16"/>
    <path d="M19.727 16.818V15L23 18.273l-3.273 3.272v-1.854c-1.997 0-3.659.636-4.727 1.854.364-1.818 2.182-4.363 4.727-4.727Z"/>
  </SvgIcon>
)

export const KNSharedViaSMSIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M19.727 16.818V15L23 18.273l-3.273 3.272v-1.854c-1.997 0-3.659.636-4.727 1.854.364-1.818 2.182-4.363 4.727-4.727Z"/>
    <path d="M13 18.96a12.758 12.758 0 0 1-4.53-.46C6.64 20 4.37 20.89 2 21c2.33-2.33 2.75-3.9 2.75-4.5A7.218 7.218 0 0 1 2 11c0-4.42 4.5-8 10-8s10 3.58 10 8a6.52 6.52 0 0 1-.508 2.519 5.95 5.95 0 0 0-1.948-.517c.295-.627.456-1.3.456-2.002 0-3.31-3.58-6-8-6s-8 2.69-8 6 3.58 6 8 6c.465 0 .922-.03 1.365-.087A5.989 5.989 0 0 0 13 18.96Z"/>
  </SvgIcon>
)

export const KNPickupPastDueIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M4 16v-6H0l7-7 6.471 6.471A7.004 7.004 0 0 1 23 16c0 3.863-3.137 7-7 7a6.995 6.995 0 0 1-5.744-3H0v-2h9.29A7.019 7.019 0 0 1 9 16H4Zm12 5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Zm.5-9H15v5l3.6 2.1.8-1.2-2.9-1.7V12ZM10 10h2.395A7.037 7.037 0 0 0 10 12.395V10Z"
    />
  </SvgIcon>
)

export const KNDeliveryPastDueIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M16 9c3.863 0 7 3.137 7 7s-3.137 7-7 7a6.995 6.995 0 0 1-5.744-3H0v-2h9.29a7.019 7.019 0 0 1-.076-3.722L7 16 0 9h4V3h6v6h6Zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5Zm.5-9H15v5l3.6 2.1.8-1.2-2.9-1.7V12Z"
    />
  </SvgIcon>
)

export const KNCrownIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      strokeLinejoin="round"
      d="M5 16 3 5l5.5 5L12 4l3.5 6L21 5l-2 11H5m14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14v1Z"
    />
  </SvgIcon>
)

export const KNNotificationsActiveIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M7.58 4.08 6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"></path>
  </SvgIcon>
)

export const KNEditIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="m13.06 7.19 3.75 3.75L7.75 20H4v-3.75l9.06-9.06Zm4.82 2.68-3.75-3.75 1.83-1.83a.996.996 0 0 1 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41l-1.83 1.83Z" />
  </SvgIcon>
)

export const KNAcuteIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 -960 960 960" {...props}>
    <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z" />
  </SvgIcon>
)

export const KNSensorsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M7.76 16.24C6.67 15.16 6 13.66 6 12s.67-3.16 1.76-4.24l1.42 1.42C8.45 9.9 8 10.9 8 12c0 1.1.45 2.1 1.17 2.83l-1.41 1.41zm8.48 0C17.33 15.16 18 13.66 18 12s-.67-3.16-1.76-4.24l-1.42 1.42C15.55 9.9 16 10.9 16 12c0 1.1-.45 2.1-1.17 2.83l1.41 1.41zM12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm8 2c0 2.21-.9 4.21-2.35 5.65l1.42 1.42C20.88 17.26 22 14.76 22 12s-1.12-5.26-2.93-7.07l-1.42 1.42C19.1 7.79 20 9.79 20 12zM6.35 6.35 4.93 4.93C3.12 6.74 2 9.24 2 12s1.12 5.26 2.93 7.07l1.42-1.42C4.9 16.21 4 14.21 4 12s.9-4.21 2.35-5.65z"></path>
  </SvgIcon>
)

export const KNTrackingIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M2.049 13H0v-2h2.049A10.01 10.01 0 0 1 11 2.049V0h2v2.049A10.01 10.01 0 0 1 21.951 11H24v2h-2.049A10.01 10.01 0 0 1 13 21.951V24h-2v-2.049A10.01 10.01 0 0 1 2.049 13ZM12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8Zm0-14c2.171 0 4 1.92 4 4.2 0 3.12-4 7.8-4 7.8s-4-4.68-4-7.8C8 7.92 9.829 6 12 6Zm-.007 5.493c.871 0 1.618-.746 1.493-1.493 0-.747-.746-1.493-1.493-1.493-.746 0-1.493.622-1.493 1.493 0 .747.622 1.493 1.493 1.493Z" />
  </SvgIcon>
)

export const KNTrackingWarningIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M19 14c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5Zm.5 8v-1h-1v1h1Zm0-2v-4h-1v4h1ZM13 24h-2v-2.049A10.01 10.01 0 0 1 2.049 13H0v-2h2.049A10.01 10.01 0 0 1 11 2.049V0h2v2.049A10.01 10.01 0 0 1 21.951 11H24v2h-4.062A8.05 8.05 0 0 0 20 12c0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8a8.05 8.05 0 0 0 1-.062V24ZM12 6c2.171 0 4 1.92 4 4.2 0 3.12-4 7.8-4 7.8s-4-4.68-4-7.8C8 7.92 9.829 6 12 6Zm-.007 5.493c.871 0 1.618-.746 1.493-1.493 0-.747-.746-1.493-1.493-1.493-.746 0-1.493.622-1.493 1.493 0 .747.622 1.493 1.493 1.493Z" />
  </SvgIcon>
)

export const KNTemperatureIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M2.049 13H0v-2h2.049A10.01 10.01 0 0 1 11 2.049V0h2v2.049A10.01 10.01 0 0 1 21.951 11H24v2h-2.049A10.01 10.01 0 0 1 13 21.951V24h-2v-2.049A10.01 10.01 0 0 1 2.049 13ZM12 20c4.41 0 8-3.59 8-8s-3.59-8-8-8-8 3.59-8 8 3.59 8 8 8Zm2-9v1.8c.6.5 1 1.3 1 2.2 0 1.7-1.3 3-3 3s-3-1.3-3-3c0-.8.4-1.6 1-2.2V8c0-1.1.9-2 2-2s2 .9 2 2h-2v1h2v1h-2v1h2Z" />
  </SvgIcon>
)

export const KNTemperatureWarningIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M14 11v1.8c.559.466.944 1.192.994 2.017a6.504 6.504 0 0 0-1.755 2.931A3.11 3.11 0 0 1 12 18c-1.7 0-3-1.3-3-3 0-.8.4-1.6 1-2.2V8c0-1.1.9-2 2-2s2 .9 2 2h-2v1h2v1h-2v1h2Zm-.986 8.936C13.06 20.623 13 24 13 24h-2v-2.049A10.01 10.01 0 0 1 2.049 13H0v-2h2.049A10.01 10.01 0 0 1 11 2.049V0h2v2.049A10.01 10.01 0 0 1 21.951 11H24v2s-3.377.06-4.064.014c.042-.332.064-.67.064-1.014 0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8c.344 0 .682-.022 1.014-.064ZM19 14c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5Zm.5 8v-1h-1v1h1Zm0-2v-4h-1v4h1Z" />
  </SvgIcon>
)
