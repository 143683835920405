import { ReactElement, Fragment } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDetailsPopoverSectionProps } from './types'

const KNDetailsPopoverSection = ({ header, description, items }: KNDetailsPopoverSectionProps): ReactElement | null => {
  if (!header && !description && items.length === 0) {
    return null
  }
  return (
    <Stack
      direction="column"
      sx={{
        paddingY: 1,
      }}
    >
      {header && (
        <KNTypography
          color="inherit"
          fontSize="0.875rem"
          fontWeight={500}
          sx={{
            paddingX: 2,
            paddingY: 0.25,
          }}
        >
          {header}
        </KNTypography>
      )}
      {description && (
        <KNTypography
          color="inherit"
          fontSize="0.875rem"
          sx={{
            paddingX: 2,
            paddingY: 0.25,
          }}
        >
          {description}
        </KNTypography>
      )}
      {items.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </Stack>
  )
}

export default KNDetailsPopoverSection
